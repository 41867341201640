<template>
  <div class="container">

    <overview :achId="achId" />
    <vendor-submitted />
    <financial-information
      v-if="this.curUserType === 'AchAdministrator'"
      :achId="achId"
      @show-bank-info="showBankInfoSuccess"
      @image-viewed="imageViewedSuccess"
    />
    <audit-trail />

  </div>
</template>

<script>

import Overview from '@/pages/ach-request/sections/Overview';
import VendorSubmitted from '@/pages/ach-request/sections/VendorSubmitted';
import FinancialInformation from '@/pages/ach-request/sections/FinancialInformation';
import AuditTrail from '@/pages/ach-request/sections/AuditTrail';

export default {
  name: "AchRequestDeleted",
  components: {
    Overview,
    VendorSubmitted,
    FinancialInformation,
    AuditTrail,
  },
  props: {
    achId: {
      type: String,
      default: '',
    },
  },
  computed: {
    // showTransfer() {
    //   let value = false;
    //   if ([ 'AchAdministrator' ].includes(this.curUserType)) {
    //     value = true;
    //   } else if (this.$store.state.ach.achData.department) {
    //     let requestDeptId = this.$store.state.ach.achData.department.id;
    //     let userDeptId = this.$store.state.users.curUserData.departmentId;
    //     if (userDeptId === requestDeptId) {
    //       value = true;
    //     }
    //   }
    //   return value;
    // },
  },
  methods: {
    showBankInfoSuccess() {
      this.$emit('achApiCallSuccess');
    },
    imageViewedSuccess() {
      this.$emit('achApiCallSuccess');
    },
    refresh() {
      // console.log('Pending.vue refresh is happening');
      this.$emit('achApiCallSuccess');
    },
  },
};

</script>
